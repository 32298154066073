import React from "react";
import img1 from "../Assets/Images/image1.jpeg";
import img2 from "../Assets/Images/image2.jpeg";
import img3 from "../Assets/Images/image3.jpeg";
import img4 from "../Assets/Images/image4.jpeg";
import img5 from "../Assets/Images/image5.jpeg";
import img6 from "../Assets/Images/image6.jpeg";

const Content = () => {
  return (
    <section className="  flex flex-col justify-center ">
      <div className="">
        <h3 className=" mt-[0px] mx-auto md:w-[50%] lg:w-[65%] w-[92%] p-2 font-[600] text-[33px] sm:text-[40px] lg:text-[48px] leading-tight lg:leading-[60px]">
          The Journey so far
        </h3>
        <p className=" mt-[5px] mx-auto md:w-[50%] lg:w-[65%] w-[92%] text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px]   p-2 ">
          Halvest started as a private investment club in 2021, growing from 15
          ethical investors to 60 in a year. We have since expanded to a
          community based platform helping our community members build their
          wealth the ethical way by giving them access to global, shari'ah
          compliant ethical investment opportunities with competitive returns.
        </p>
        {/* <p className=" mt-[20px] md:w-[50%] w-[92%] lg:w-[65%] text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px]   p-2 mx-auto  ">
          Our sole purpose is to enable investors to grow their wealth without
          doubting their ethics or compromising their financial returns. With
          200+ investors currently, we have processed $4million+ transaction
          value in 65+ investment campaigns and have achieved a 99.5% success
          rate across all investment campaigns, consistently delivering
          competitive returns.
        </p> */}

        <div className="grid w-[92%] my-4 lg:w-[65%] mx-auto grid-cols-2 sm:grid-cols-3 gap-3">
          <img
            src={img4}
            alt=""
            className="  object-cover w-full h-[260px] overflow-hidden object-top   "
          />
          <img
            src={img2}
            alt=""
            className="  object-cover w-full h-[260px] overflow-hidden object-top   "
          />
          <img
            src={img3}
            alt=""
            className="  object-cover w-full h-[260px] overflow-hidden object-top   "
          />
          <img
            src={img1}
            alt=""
            className="  object-cover w-full h-[260px] overflow-hidden object-top   "
          />
          <img
            src={img5}
            alt=""
            className="  object-cover w-full h-[260px] overflow-hidden object-top   "
          />
          <img
            src={img6}
            alt=""
            className="  object-cover w-full h-[260px] overflow-hidden object-top   "
          />
        </div>
      </div>
    </section>
  );
};

export default Content;
