import React, { useState } from "react";
import opportunities1 from "../Assets/Images/paint-board.png";
import opportunities2 from "../Assets/Images/web-design-01.png";
import opportunities3 from "../Assets/Images/hold-phone.png";
import opportunities4 from "../Assets/Images/wallet-02.png";
import { Client } from "../client";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const Card = ({ image, title, description }) => {
  return (
    <div className=" w-full lg:w-auto bg-white flex flex-col  p-6 ">
      <div className="flex items-start gap-[7px] flex-col mb-2">
        <img src={image} alt={title} className="w-[48px] h-[48px] mr-4" />
        <h3 className="font-semibold text-[24px] text-[#101828]">{title}</h3>
      </div>
      <div className=" flex justify-start ">
        <p className="text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px] text-[#475467]">
          {description}
        </p>
      </div>
    </div>
  );
};

const Opportunities = () => {
  const cards = [
    {
      image: opportunities1,
      title: "Rebranding",
      description:
        "How you feel when you see and hear Halvest is very important to us. We want you to think of Halvest as a safe space for your wealth to grow without doubting the ethics.",
    },
    {
      image: opportunities2,
      title: "Expanding Product Offerings",
      description:
        "We are bringing more ethical investment opportunities for you to diversify your portfolio regardless of your experience or income level.",
    },
    {
      image: opportunities3,
      title: "Building a Mobile App",
      description:
        "We are developing an all-in-one mobile app where you'll be able to explore unlimited ethical investment opportunities, effortlessly track and manage your portfolio, automate processes, and do so much more. ",
    },
    {
      image: opportunities4,
      title: "Improving Current Features",
      description:
        "We are improving the current features on our web app. You can look forward to enhanced wallet features, more product offerings, a better web app interface, and many more.",
    },
  ];

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const { data } = await Client({
          method: "POST",
          path: "https://api.halvestco.com/api/v1/subscribe/add",
          data: {
            email: values?.email,
          },
          type: "full-path",
        });
        resetForm();
        toast.success(
          "You’re in! You’re now on the list to stay updated on our journey."
        );
      } catch (error) {
        resetForm();
        toast.error(
          error?.response?.data?.errors?.email[0] ||
            error?.response?.data?.message
        );
      }

      setSubmitting(false);
    },
  });

  return (
    <div className="mt-14">
      <div className="w-full  md:w-[60%] lg:w-[1000px] mx-auto px-4">
        <h2 className="text-[33px] sm:text-[40px] text-left lg:text-[48px] text-[#101828] leading-tight lg:leading-[60px] font-[600]  mb-3">
          Where are we headed to?
        </h2>
        <p className="mt-2 text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px] text-left font-normal text-[#344054]">
          We're making global, ethical, and Shariah-compliant investments
          accessible to everyone, including YOU, in a simpler way, no matter
          your income level. At the core of our journey is accessibility, and we
          want Africans, both at home and in the diaspora, to invest ethically
          without barriers.
        </p>
      </div>

      <div className="md:w-[60%] justify-center mt-5 mb-10 px-4 lg:w-[1000px] mx-auto flex flex-col md:flex-row  items-center gap-[10px] md:gap-[12px]">
        <input
          onChange={form.handleChange}
          value={form?.values?.email}
          id="email"
          type="email"
          className="mt-[5px] outline-none md:mt-0 px-4 py-2 border border-[159AA8] w-full md:w-[385px] h-[50px] rounded-lg"
          placeholder="Your email"
        />
        <button
          onClick={form.handleSubmit}
          className={` h-[50px] px-6 w-full transform transition hover:scale-105 duration-300 ease-in-out sm:w-auto font-[600] flex items-center justify-center rounded-lg text-white bg-[#159AA8] ${
            form?.isValid ? "" : "bg-[rgba(21,154,168,0.7)] cursor-not-allowed"
          }`}
        >
          {form.isSubmitting ? (
            <img
              width="24px"
              height="24px"
              alt="Button Icon"
              src={"/Assets/loading.gif"}
            />
          ) : (
            "Stay updated on our Journey."
          )}
        </button>
      </div>

      <h2 className="w-full md:w-[60%] lg:w-[1000px] mx-auto px-4 text-[33px] sm:text-[40px] text-left lg:text-[48px] text-[#101828] leading-tight lg:leading-[60px] font-[600]  mb-1">
        We are starting with:
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8  w-full lg:w-[1000px] mx-auto mb-4 ">
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Opportunities;
