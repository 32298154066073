import React, { useRef, useState } from "react";
import plusIcon from "../Assets/Images/plus.png";
import minusIcon from "../Assets/Images/minus.png";
import { useFormik } from "formik";
import { Client } from "../client";
import * as Yup from "yup";
import { toast } from "react-toastify";

const FAQItem = ({ id, question, answer, isOpen, toggleOpen }) => {
  return (
    <div className={`${id === 0 ? "" : "border-t border-t-gray-200"} py-5`}>
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => toggleOpen(isOpen === id ? null : id)}
      >
        <h3 className="text-lg font-[500] text-[18px] lg:text-xl">
          {question}
        </h3>
        <img
          src={isOpen === id ? minusIcon : plusIcon}
          alt={isOpen === id ? "Collapse" : "Expand"}
          className="w-[16px] h-[16px] object-contain"
        />
      </div>
      {isOpen === id && (
        <p className="mt-2 text-gray-600 text-base lg:text-[18px]">{answer}</p>
      )}
    </div>
  );
};

const FAQ = () => {
  const faqRef = useRef(0);
  const [isOpen, setIsOpen] = useState(null);

  const toggleOpen = (id) => {
    setIsOpen(id);
  };
  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const { data } = await Client({
          method: "POST",
          path: "https://api.halvestco.com/api/v1/subscribe/add",
          data: {
            email: values?.email,
          },
          type: "full-path",
        });

        resetForm();
        toast.success(
          "You’re in! You’re now on the list to stay updated on our journey."
        );
      } catch (error) {
        resetForm();
        toast.error(
          error?.response?.data?.errors?.email[0] ||
            error?.response?.data?.message
        );
      }

      setSubmitting(false);
    },
  });

  const faqData = [
    {
      question: "Why are we rebranding?",
      answer:
        "We want Halvest to be your go-to place for growing your wealth the ethical way. To match our goals and provide you with the best experience, we're giving our look a revamp that suits our goals.",
    },
    {
      question: "Why are we building new products?",
      answer:
        " We’re dedicated to making ethical investing accessible to YOU and other African investors. That’s why we’re expanding our product offerings and creating an all-in-one mobile app to simplify your ethical investment journey.",
    },
    {
      question: "When will the new products be accessible?",
      answer:
        " We cannot give a definite timeline for when these products will be accessible. We will be building these products in public and sharing updates on our milestones as we progress.",
    },
    {
      question: "How will this impact existing users?",
      answer:
        "Building new products won’t negatively impact our current users. You’ll still have full access to your existing products and activities on the Halvest Portal. If there are any changes to how you access the portal, we’ll let you know right away.",
    },
  ];

  return (
    <>
      <section className="  flex flex-col justify-center  w-full">
        <div className=" mt-[5px] mb-4 w-full">
          <h2 className="text-[33px] md:w-[60%] w-[92%] lg:w-[1000px] sm:text-[40px] text-left lg:text-[48px] text-[#101828] leading-tight lg:leading-[60px] font-[600] mx-auto  p-2 mb-2">
            We want YOU to see us do this.
          </h2>
          <p className=" mt-[10px] md:w-[60%] lg:w-[1000px] text-[#344054] font-[400] text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px]   w-[92%] p-2   mx-auto  ">
            Our main goal is simple; we want you to see how we're expanding
            Halvest to make ethical investing accessible to YOU and to other
            African investors, both at home and in the diaspora.
          </p>

          {/* <p className=" mt-[10px] md:w-[60%] lg:w-[1000px] text-[#344054] font-[400] text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px]   w-[92%] p-2   mx-auto  ">
            We have begun our rebranding process, unveiling new colors and a new
            logo, and laying the groundwork for new features. We will keep you
            updated as we reach different milestones. Having made ethical
            investing accessible to over 200 investors and surpassing $4 million
            in transaction value, we want you to witness how we expand Halvest
            to make ethical investing accessible for Africans both at home and
            in the diaspora. Do you have more questions? Read our FAQs here.
          </p>
          <p className=" mt-[10px] md:w-[60%] lg:w-[1000px] text-[#344054] font-[400] text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px]   w-[92%] p-2   mx-auto  ">
            Do you have more questions? Read our FAQs{" "}
            <span
              onClick={() => {
                window.scrollTo({
                  top: faqRef?.current?.offsetTop,
                  behavior: "smooth",
                });
              }}
              className="cursor-pointer !text-[#175CD3] underline text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px] font-[400]"
            >
              here
            </span>
          </p> */}
        </div>
      </section>
      <section className="pt-7 px-4 ">
        <div className="h-auto py-12 sm:py-auto sm:h-[478px] mx-auto rounded-xl w-full md:w-[80%] bg-gradient-to-br from-[#159AA8] to-[#161B26] px-3 sm:px-8  md:px-12  flex flex-col items-center justify-center">
          <h2 className="font-[600] max-w-[100%] sm:max-w-[75%] md:max-w-[713px] mx-auto text-[20px] sm:text-[40px] lg:text-[48px] leading-tight lg:leading-[60px] text-white text-center">
            Get updates on how we make ethical investing accessible to YOU and
            other africans.
          </h2>
          <div className="mt-[20px] md:mt-[40px] w-[96%] sm:w-[75%] flex flex-col md:flex-row justify-center items-center gap-[10px] md:gap-[12px]">
            <input
              onChange={form.handleChange}
              value={form?.values?.email}
              id="email"
              type="email"
              className="mt-[5px] outline-none md:mt-0 px-4 py-2 w-full md:w-[385px] h-[50px] rounded-lg"
              placeholder="Your email"
            />
            <button
              onClick={form.handleSubmit}
              className={`w-full transform transition hover:scale-105 duration-300 ease-in-out md:w-[170px] h-[50px] p-2 font-[600] flex justify-center items-center rounded-lg text-white bg-black
              ${form?.isValid ? "" : "opacity-[0.7] cursor-not-allowed"}
                `}
            >
              {form.isSubmitting ? (
                <img
                  width="24px"
                  height="24px"
                  alt="Button Icon"
                  src={"/Assets/loading.gif"}
                />
              ) : (
                "Stay Updated"
              )}
            </button>
          </div>
        </div>
      </section>
      <section
        ref={faqRef}
        id="faq"
        className="faq max-w-[100%] md:max-w-[65%] pt-[80px] mx-auto p-6"
      >
        <h2 className="text-[33px] sm:text-[40px] lg:text-[48px] leading-tight lg:leading-[60px] font-[600] text-center mb-3">
          Frequently Asked Questions
        </h2>
        {/* <p className="text-center px-[2%] text-base sm:text-[18px] leading-tight  lg:text-xl lg:leading-[30px] text-[#475467] font-[400] mb-3">
          Everything you need to know about the Halvest BIP
        </p> */}
        <div className="bg-white p-6">
          {faqData.map((faq, index) => (
            <FAQItem
              isOpen={isOpen}
              toggleOpen={toggleOpen}
              setIsOpen={setIsOpen}
              key={index}
              id={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default FAQ;
