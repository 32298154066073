import Home from "./Pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import Whatsapp from "./Components/Whatsapp";
import { useCookies } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";

// const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4739";
// const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4739";

// Replace YOUR_TOKEN with your Project Token
// mixpanel.init(PROJECT_TOKEN, {
//   debug: true,
//   ignore_dnt: true,
//   track_pageview: "full-url",
// });

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

function App() {
  useEffect(() => {
    // mixpanel.track("Website-URL-Launched");
  }, []);

  return (
    <>
      <HelmetProvider>
        <RouterProvider router={router} />

        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          ltr={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          hideProgressBar={true}
        />
        <Whatsapp />
      </HelmetProvider>
    </>
  );
}

export default App;
