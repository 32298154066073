import React from "react";
// import hero from "../Assets/Images/Container.png";
import hero1 from "../Assets/Images/hero1.png";
// import hero2 from "../Assets/Images/hero2.png";
// import hero3 from "../Assets/Images/hero3.png";
import hero4 from "../Assets/Images/hero4.jpg";
import { Carousel } from "react-responsive-carousel";

const Hero = () => {
  return (
    <div className="pt-20 sm:pt-24 px-3 md:px-7 flex items-center justify-center min-h-screen">
      <div className=" flex flex-col items-center justify-center text-center">
        <div className="flex flex-col w-full mx-auto">
          <h1 className="mb-6 max-w-[80%] mx-auto  text-[#101828] text-[36px] md:text-[55px] lg:text-[78px] font-[600] leading-tight">
            We are expanding <br />
            Halvest to YOU!
          </h1>
          <p className="mb-4 text-[#475467] max-w-[95%]   sm:max-w-[768px] px-3 text-[15px] sm:text-[18px] leading-normal  lg:text-xl lg:leading-[30px]  mx-auto">
            Be a part of our journey as we build new products, improve existing
            features, and make ethical investing accessible to YOU.
          </p>
          <div className="w-full mt-6 flex justify-center">
            <Carousel
              className="carousel"
              autoPlay
              emulateTouch
              swipeable
              showStatus={false}
              infiniteLoop
              showThumbs={false}
              showArrows={false}
            >
              <img
                src={hero1}
                alt="Hero"
                className="w-full max-w-md md:max-w-[70%] h-[450px] sm:h-[550px] object-cover object-top overflow-hidden rounded-[16px]"
              />
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
