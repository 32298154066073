import React from "react";

const Stats = () => {
  return (
    <div className="mt-[80px] max-w-[95%] flex justify-around items-center mb-[40px] rounded-xl mx-auto md:w-[50%] lg:w-[65%] bg-[#159AA8] h-auto py-5 md:py-8 px-3 ">
      <div className="flex justify-around items-center md:flex-row flex-col  w-full">
        <div className="flex gap-[10px] items-center flex-col md:flex-row">
          <h1 className="text-[50px] font-bold text-[#FFF] ">$7.5m+</h1>
          <h3 className="text-[20px] text-center text-[#FFF]  md:text-left">
            Transaction
            <br /> Value
          </h3>
        </div>

        <div className=" md:block hidden w-[1px] h-[40px] bg-[#e9e6e5] "></div>

        <div className="flex gap-[10px] items-center flex-col md:flex-row">
          <h1 className="text-[50px] text-[#FFF] font-bold">75+</h1>
          <h3 className="text-[20px] text-[#FFF] text-center md:text-left">
            Investment <br /> Campaigns
          </h3>
        </div>

        <div className=" w-[1px] md:block hidden h-[40px] bg-[#e9e6e5] "></div>

        <div className="flex gap-[10px] items-center flex-col md:flex-row">
          <h1 className="text-[50px] font-bold  text-[#FFF]">250+</h1>
          <h3 className="text-[20px] text-center text-[#FFF] md:text-left">
            Investors <br />
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Stats;
