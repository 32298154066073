import React, { useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { FaChevronDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import mixpanel from "mixpanel-browser";

const Menu = ({ menuOpen, setMenuOpen }) => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const handleClickLink = (param) => {
    navigate(param);
    setMenuOpen(false);
  };
  const links = [];
  return (
    <div className="">
      <div className="flex flex-col items-center menu-height lg:hidden ">
        <div className="pt-10  flex flex-col items-center gap-4">
          <div className="">
            <a href="https://halvestco.com/" target="_blank">
              <h4 className="inline-block text-[#159AA8] cursor-pointer no-underline hover:text-[#159AA8] text-base font-[700] py-2 px-4">
                Visit Halvestco.com
              </h4>
            </a>
          </div>

          <a
            className=""
            target="_blank"
            rel="noreferrer"
            href="mailto:support@halvestco.com"
          >
            <div className="flex justify-center items-center bg-[#159AA8] w-[162px] h-[56px] rounded-[8px]  transform transition hover:scale-105 duration-300 ease-in-out">
              <h4 className="inline-block text-[#fff] no-underline cursor-pointer text-base font-semibold  ">
                Contact Us
              </h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <nav
        id="header"
        className="fixed py-[18px] px-3 md:px-[5%]  w-full z-30 top-0 bg-white"
      >
        <div className="w-full  mx-auto flex  items-center justify-between mt-0 pt-[2px] pb-[2px]">
          <div className="flex gap-10 items-center">
            <div className="pl-4 flex items-center">
              <img
                onClick={() => {
                  // mixpanel.track_pageview({ page: "Website-Home-Page" });
                  navigate("/");
                }}
                src={require("../Assets/Logo/halvest.png")}
                className="w-[158px] cursor-pointer h-[24px]"
              />
            </div>
            <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20">
              <ul className="list-reset lg:flex justify-end flex-1 items-center"></ul>
            </div>
          </div>
          <div className="block lg:hidden pr-4">
            <button
              id="nav-toggle"
              className="flex items-center p-1 text-[#475467] hover:text-gray-900 focus:outline-none focus:shadow-outlinet"
            >
              {!menuOpen ? (
                <svg
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="fill-current h-6 w-6"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              ) : (
                <span onClick={() => setMenuOpen(!menuOpen)}>
                  <AiOutlineClose size={24} color="black" />
                </span>
              )}
            </button>
          </div>
          <div className="hidden  gap-3 lg:flex lg:items-center ">
            <div>
              <a href="https://halvestco.com/" target="_blank">
                <h4 className="inline-block text-[#159AA8] cursor-pointer no-underline hover:text-[#159AA8] text-base font-[700] py-2 px-4">
                  Visit Halvestco.com
                </h4>
              </a>
            </div>
            <a
              className=""
              target="_blank"
              rel="noreferrer"
              href="mailto:support@halvestco.com"
            >
              <div className="flex justify-center items-center bg-[#159AA8] w-[162px] h-[56px] rounded-[8px]  transform transition hover:scale-105 duration-300 ease-in-out">
                <h4 className="inline-block text-[#fff] no-underline cursor-pointer text-base font-semibold  ">
                  Contact Us
                </h4>
              </div>
            </a>
          </div>
        </div>
        <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
      </nav>
      {menuOpen && (
        <div
          className={`fixed mt-[80px] w-full h-full z-50 bg-white ${
            menuOpen ? "menu-wrapper " : " menu-hidden"
          }`}
        >
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Header;
