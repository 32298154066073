import React from "react";
import { GrTwitter, GrLinkedinOption, GrInstagram } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Halvestlogo from "../Assets/SVGS/Halvestlogo";
import mixpanel from "mixpanel-browser";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-gradient-to-br from-[#159AA8] to-[#161B26] px-3 py-16">
      <div className=" mx-auto flex flex-col lg:flex-col items-center justify-between space-y-6 lg:space-y-0">
        <div className="flex flex-col items-center lg:flex-col gap-[20px] lg:justify-between w-full lg:w-auto lg:space-x-10">
          <div className="flex justify-center lg:justify-start w-full lg:w-auto mb-6 lg:mb-0">
            <Halvestlogo />
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap md:mt-[20px] gap-2 md:gap-6 justify-center items-center lg:items-start space-y-3 md:space-y-0">
            {/* <a
              href="https://halvestco.com/about-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-[#FFFFFF] text-[18px] font-[600] cursor-pointer transition-all ">
                About Us
              </p>
            </a> */}
            {/* <a
              href="https://halvestco.com/membership"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-[#FFFFFF] text-[18px] font-[600] cursor-pointer transition-all ">
                Membership
              </p>
            </a> */}
            {/* <a
              href="https://halvestco.com/Legal/Terms.docx.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-[#FFFFFF] text-[18px] font-[600] cursor-pointer transition-all ">
                Terms Of Service
              </p>
            </a> */}
            {/* <a
              className=""
              target="_blank"
              rel="noreferrer"
              href="mailto:support@halvestco.com"
            >
              <p className="text-[#FFFFFF] text-[18px] font-[600] cursor-pointer transition-all ">
                Support
              </p>
            </a> */}
          </div>
        </div>
        <div className="flex flex-col items-center  space-y-3">
          <div className="flex items-center mb-4 justify-center  md:mt-[50px] space-x-4">
            <a
              href="https://www.linkedin.com/company/halvestco/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-[27px] h-[27px]"
            >
              <GrLinkedinOption
                size={20}
                className="text-white cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
              />
            </a>
            <a
              href="https://www.instagram.com/halvestco?igsh=MWx3N2dta2ZyendpZg=="
              target="_blank"
              rel="noopener noreferrer"
              className="w-[27px] h-[27px]"
            >
              <GrInstagram
                size={20}
                className="text-white cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
              />
            </a>
          </div>
          <p className="text-[#FFFFFF] mt-3 text-[14px] font-[400]">
            © 2024 Halvest
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
