import React from "react";

const Halvestlogo = () => {
  return (
    <svg
      width="158"
      height="24"
      viewBox="0 0 187 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_48447)">
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M73.1382 16.8586H59.8193V28.8562H54.2827V0.510254H59.8193V11.7168H73.1382V0.510254H78.697V28.8562H73.1382V16.8586Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M92.0552 16.4632C92.0256 15.4671 91.7254 14.665 91.1547 14.0571C90.584 13.4491 89.8465 13.1452 88.9423 13.1452C87.8899 13.1452 87.0709 13.3941 86.4853 13.8923C85.8998 14.3904 85.607 15.1741 85.607 16.2434C85.607 16.4486 85.6144 16.6097 85.6292 16.7269H80.7598C80.7449 16.5658 80.7375 16.3386 80.7375 16.0456C80.7375 14.5369 81.0821 13.2221 81.7715 12.1015C82.4607 10.9808 83.4057 10.1237 84.6065 9.53047C85.8071 8.9372 87.1561 8.64062 88.6532 8.64062C90.2393 8.64062 91.6772 8.9372 92.9669 9.53047C94.2565 10.1237 95.2793 11.0063 96.0354 12.1783C96.7913 13.3502 97.1693 14.7639 97.1693 16.4192V28.9002H91.9885V27.8015C90.6099 28.8563 88.7792 29.3836 86.4964 29.3836C85.3105 29.3836 84.2358 29.1456 83.2723 28.6695C82.3088 28.1934 81.5454 27.5085 80.9821 26.615C80.4188 25.7213 80.1372 24.674 80.1372 23.4727C80.1372 21.5831 80.8339 20.0925 82.2273 19.0012C83.6206 17.9097 85.5773 17.2176 88.0973 16.9247L92.0552 16.4632ZM89.098 20.9018C87.6304 21.0629 86.6409 21.334 86.1296 21.7149C85.6182 22.0957 85.3624 22.5865 85.3624 23.187C85.3624 24.4322 86.0591 25.0548 87.4526 25.0548C88.6977 25.0548 89.7427 24.652 90.5877 23.8463C91.4326 23.0407 91.9144 21.9566 92.033 20.5942L89.098 20.9018Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M104.122 0.609375H99.3638V28.9553H104.122V0.609375Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M124.069 5.31171V0.609375H152.753V5.31171H124.069Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M113.835 28.8562L105.586 9.18987H111.256L116.326 21.3632L124.069 0.609375H129.921L118.416 28.8562H113.835Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M144.826 27.4939C143.21 28.7684 141.053 29.4056 138.355 29.4056C136.547 29.4056 134.89 28.9808 133.386 28.1311C131.881 27.2815 130.691 26.0876 129.817 24.5494C128.942 23.0113 128.505 21.2534 128.505 19.2758C128.505 17.2836 128.938 15.478 129.806 13.8593C130.673 12.2406 131.877 10.9661 133.419 10.0359C134.961 9.10567 136.71 8.64062 138.666 8.64062C140.608 8.64062 142.294 9.0691 143.725 9.92606C145.155 10.783 146.249 11.9622 147.005 13.4638C147.761 14.9653 148.139 16.6462 148.139 18.5067V20.77H133.908C134.175 22.0005 134.753 22.993 135.642 23.7474C136.532 24.5018 137.569 24.8791 138.755 24.8791C139.689 24.8791 140.456 24.7802 141.057 24.5824C141.657 24.3846 142.161 24.0294 142.569 23.5167C142.976 23.004 143.314 22.2716 143.58 21.3194H148.316C147.931 22.9307 147.468 24.1905 146.927 25.0988C146.386 26.007 145.685 26.8054 144.826 27.4939ZM141.557 14.3097C140.86 13.4895 139.882 13.0793 138.622 13.0793C137.51 13.0793 136.558 13.4052 135.765 14.0571C134.972 14.7089 134.42 15.5989 134.108 16.7269H142.824C142.646 15.9504 142.224 15.1447 141.557 14.3097Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M160.252 29.4056C157.702 29.4056 155.612 28.7537 153.982 27.45C152.351 26.1463 151.432 24.0953 151.225 21.2974H155.916C156.094 22.7037 156.52 23.6815 157.195 24.2309C157.869 24.7802 158.851 25.0548 160.141 25.0548C161.371 25.0548 162.249 24.89 162.776 24.5604C163.302 24.2309 163.565 23.839 163.565 23.3848C163.565 23.0479 163.502 22.7733 163.376 22.5609C163.25 22.3485 163.002 22.1616 162.631 22.0005C162.261 21.8394 161.705 21.6929 160.964 21.5611L157.917 21.0337C155.975 20.6968 154.475 20.0375 153.415 19.056C152.355 18.0746 151.825 16.7488 151.825 15.0788C151.825 13.7604 152.158 12.6178 152.825 11.6509C153.493 10.6841 154.43 9.94067 155.638 9.42065C156.846 8.90063 158.236 8.64062 159.807 8.64062C162.327 8.64062 164.332 9.28875 165.822 10.5852C167.312 11.8817 168.183 13.9069 168.435 16.661H163.899C163.75 15.6941 163.509 14.9469 163.176 14.4197C162.842 13.8923 162.394 13.5224 161.831 13.31C161.267 13.0976 160.526 12.9913 159.607 12.9913C158.673 12.9913 157.98 13.1598 157.528 13.4967C157.076 13.8337 156.85 14.2438 156.85 14.7273C156.85 15.1228 156.991 15.4524 157.272 15.716C157.554 15.9797 158.058 16.1702 158.785 16.2873L162.12 16.8586C164.254 17.2395 165.866 17.9025 166.956 18.8473C168.046 19.7922 168.59 21.2094 168.59 23.0992C168.59 24.403 168.223 25.5309 167.49 26.4831C166.756 27.4354 165.759 28.1604 164.499 28.6585C163.239 29.1565 161.823 29.4056 160.252 29.4056Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M173.455 13.1888H170.876V8.79412H173.588V3.74023H178.48V8.79412L186.419 8.88738V13.2821L178.48 13.1888V23.2967C178.48 24.1756 178.829 24.6152 179.525 24.6152C179.955 24.6152 180.522 24.4613 181.226 24.1537C181.93 23.8461 185.625 21.8926 186.647 21.2773L186.93 25.7052C186.263 26.2473 182.29 28.3945 181.237 28.7901C180.185 29.1856 179.162 29.3834 178.169 29.3834C176.79 29.3834 175.66 28.9402 174.778 28.054C173.896 27.1677 173.455 25.7211 173.455 23.7142V13.1888Z"
          fill="#0898A0"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M29.1097 20.1682C29.0213 19.9586 21.7531 5.7253 21.6338 5.529C21.2518 4.62305 20.5801 3.82704 19.657 3.29277C17.3988 1.98456 14.4952 2.73319 13.1718 4.96494C12.2638 6.49555 12.3355 8.32641 13.1986 9.74754C13.287 9.9573 20.2437 23.5805 20.3626 23.7768C20.4513 23.9868 20.5552 24.1906 20.6745 24.3869C21.0563 25.2931 21.7282 26.0888 22.6513 26.6235C24.9095 27.9313 27.8131 27.1823 29.1365 24.9509C30.0443 23.4203 29.9728 21.5897 29.1097 20.1682Z"
          fill="#BDE7EF"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M20.7162 10.536C21.5118 9.69812 21.9997 8.57219 21.9997 7.33263C21.9997 4.74601 19.8779 2.64893 17.2606 2.64893C15.6504 2.64893 14.2296 3.44252 13.3728 4.65493C13.2166 4.81961 1.41478 19.1928 1.28352 19.3783C0.488155 20.2162 0 21.3424 0 22.5819C0 25.1685 2.12205 27.2654 4.73947 27.2654C6.34904 27.2654 7.77033 26.4716 8.62702 25.2593C8.78342 25.0949 20.5849 10.7215 20.7162 10.536Z"
          fill="#159AA8"
        />
        <path
          className="fill-[rgba(253,254,255,0.93)]"
          d="M41.0242 10.537C41.8199 9.6991 42.3078 8.57316 42.3078 7.33361C42.3078 4.74699 40.186 2.6499 37.5686 2.6499C35.9587 2.6499 34.5377 3.4435 33.6808 4.65591C33.5246 4.82058 21.7231 19.1938 21.5919 19.3793C20.7963 20.2172 20.3083 21.3433 20.3083 22.5829C20.3083 25.1695 22.4302 27.2664 25.0475 27.2664C26.6573 27.2664 28.0784 26.4725 28.935 25.2602C29.0914 25.0959 40.8929 10.7225 41.0242 10.537Z"
          fill="#159AA8"
        />
      </g>
      <defs>
        <clipPath id="clip0_639_48447">
          <rect width="187" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Halvestlogo;
