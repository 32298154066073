import React, { useEffect } from "react";
import Header from "../Components/Header";
import SEO from "../Components/Seo";
import Footer from "../Components/Footer";
import Faq from "../Components/Faq";
import Cta from "../Components/Cta";
import Hero from "../Components/Hero";
import Features from "../Components/Features";
import Content from "../Components/Content";
import Oppurtunities from "../Components/Oppurtunities";

import Stats from "../Components/Stats";

const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <SEO
        title={
          "Ethical Finance & Islamic Banking | Halvest - Africa's #1 Digital Investment Platform"
        }
        description={
          "Discover ethical finance and Islamic banking solutions with Halvest. We offer Sharia-compliant investments, halal finance, and sustainable investing options with competitive returns."
        }
        name={"Halvest"}
        type={"article"}
      />
      <Header />
      <Hero />

      <Stats />
      <Content />
      <Oppurtunities />
      {/* <Information gotToFaq={faqRef} /> */}

      <Faq />
      {/* <Cta /> */}
      <Footer />
    </div>
  );
};

export default Home;
